import React from "react"
import { Helmet } from "react-helmet"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Projects from "../components/Projects"

const ProjectsPage = () => (
  <div>
    <Helmet>
      <title>Endorfina</title>
      <meta
      name="Endorfina, endorfina concept, building happiness, architecture, Romania, Cluj, Cluj-Napoca, arhitectura, urbanism, design, design interior"
      content="architecture, Romania, Cluj, Cluj-Napoca, arhitectura, urbanism, design, design interior"
      />
    </Helmet>
    <Navbar />
    <Projects />
    <Footer />
  </div>
)

export default ProjectsPage
