import React, { Component } from "react";
import { MDBContainer, MDBRow, MDBCol, MDBNav, MDBNavItem, MDBTabPane, MDBTabContent } from "mdbreact";
import { Link } from "gatsby";

import { projectType, subProjectTypeArch } from "../resources/constants";
import { projectData } from "../resources/projectData";

class Projects extends Component {
    state = {
        items: {
            default: projectType.arhitectura,
            test: subProjectTypeArch.semicolective
        }
    };

    togglePills = (type, tab) => (e) => {
        e.preventDefault();
        if (this.state.items[type] !== tab) {
            if (type === "default") {
                let data = projectData.find((data) => data.type === tab);
                if (data) {
                    if (data.subTypes) {
                        this.setState((prevState) => ({
                            items: {
                                ...prevState.items,
                                test: data.subTypes[0].type
                            }
                        }));
                    }
                }
            } else {
                this.setState((prevState) => ({
                    items: {
                        ...prevState.items,
                        test: ""
                    }
                }));
            }
            this.setState((prevState) => ({
                items: {
                    ...prevState.items,
                    [type]: tab
                }
            }));
        }
    };

    renderNavbar = (items, active) => {
        return (
            <MDBNav className="nav-pills d-flex justify-content-center">
                {items.map((item, index) => (
                    <MDBNavItem key={index}>
                        <Link
                            to="#"
                            onClick={this.togglePills("test", item.type)}
                            className={`nav-link ${active === item.type ? "active" : ""}`}
                        >
                            {item.type}
                        </Link>
                    </MDBNavItem>
                ))}
            </MDBNav>
        );
    };

    renderTabContent = (subMenuItems, type) => {
        const activeTab = this.state.items["test"];

        return subMenuItems.map((item) => {
            const isActiveTab = item.type === activeTab;

            return (
                <MDBTabPane
                    key={item.type}
                    tabId={item.type}
                    className={`row ${isActiveTab ? "active" : ""}`}
                >
                    {isActiveTab && this.renderProjects(item, type)}
                </MDBTabPane>
            );
        });
    };

    goThroughProjectData = (dataList) => {
        return dataList.map((data) => (
            <React.Fragment key={data.type}>
                {data.projects ? (
                    <React.Fragment key={data.type}>
                        {this.renderProjects(data)}
                    </React.Fragment>
                ) : (
                    data.subTypes && (
                        <React.Fragment key={data.type}>
                            {this.goThroughProjectData(data.subTypes)}
                        </React.Fragment>
                    )
                )}
            </React.Fragment>
        ));
    };

    renderProjects = (data, type) => {
        const tipList = [type, data.type];
        return (
            <div key={data.type} className="row">
                {data.projects.map((project, index) => (
                    <div key={index + 1} className="col-lg-4 col-md-12 mb-4">
                        <Link
                            to={`/detaliiProiect?tip=${encodeURIComponent(
                                JSON.stringify(tipList)
                            )}&titlu=${project.title}`}
                        >
                            <div className="view overlay zoom z-depth-2">
                                <img
                                    src={project.projectImage}
                                    alt="Poza proiect"
                                    className="img-fluid"
                                />
                            </div>
                            <p className="text-uppercase text-center font-weight-bold text-muted my-4">
                                {project.title}
                            </p>
                        </Link>
                    </div>
                ))}
            </div>
        );
    };

    render() {
        const active = this.state.items["default"];
        const active2 = this.state.items["test"];

        return (
            <>
                <MDBContainer className="mt-4 container pt-5 my-5">
                    <MDBContainer className="p-md-3 ">
                        <h2 className="text-center mx-auto font-weight-bold mb-4 pb-2">
                            Proiecte
                        </h2>
                        <MDBRow>
                            <MDBCol md="12">
                                <MDBNav className="nav-pills d-flex justify-content-center">
                                    {projectData.map((item, index) => (
                                        <MDBNavItem key={index}>
                                            <Link
                                                to="#"
                                                onClick={this.togglePills("default", item.type)}
                                                className={`nav-link ${active === item.type ? "active" : ""
                                                    }`}
                                            >
                                                {item.type}
                                            </Link>
                                        </MDBNavItem>
                                    ))}
                                </MDBNav>
                                <MDBTabContent activeItem={active}>
                                    {projectData.map((item) => (
                                        <MDBTabPane
                                            key={item.type}
                                            tabId={item.type}
                                            className={`row ${active === item.type ? "active" : ""}`}
                                        >
                                            {item.subTypes ? (
                                                <>
                                                    {this.renderNavbar(item.subTypes, active2)}
                                                    <MDBTabContent activeItem={active2}>
                                                        {this.renderTabContent(item.subTypes, item.type)}
                                                    </MDBTabContent>
                                                </>
                                            ) : (
                                                this.renderProjects(item)
                                            )}
                                        </MDBTabPane>
                                    ))}
                                </MDBTabContent>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBContainer>
            </>
        );
    }
}

export default Projects;
